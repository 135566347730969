body {
  font-optical-sizing: auto;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 400;
}

.footer-link:hover {
  text-decoration: underline #e2c838;
  text-decoration-thickness: 2px;
}

@media (min-width: 1000px) {
  .dropdown-user .dropdown-menu.show {
    left: -50px;
  }
}

/*# sourceMappingURL=aquarium.be5ac503.css.map */
