body {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.footer-link:hover{
    text-decoration: underline;
    text-decoration-color: rgb(226, 200, 56);
    text-decoration-thickness: 2px;
}


@media (width >1000px) {
    .dropdown-user .dropdown-menu.show {
        left: -50px;
    }
}